import { template as template_51e44084101e46b7be87274cc90def9c } from "@ember/template-compiler";
const FKLabel = template_51e44084101e46b7be87274cc90def9c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
